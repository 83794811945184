import React from 'react';
import usePreviewData from '../../../utils/usePreviewData';
import EventPage from '../../../components/Event/Page';
import GraduatePage from '../../../components/Graduates/Page';
import PrismicPage from '../../../components/Layout/Page/Prismic';
import IndexPage from '../..';

const getPageTemplate = node => {
  switch (node.type) {
    case 'homepage':
      return <IndexPage pageContext={{ data: node }} />;
    case 'page':
      return <PrismicPage pageContext={{ data: node }} />;
    case 'graduate-member':
      return <GraduatePage pageContext={{ data: node }} />;
    case 'event':
      return <EventPage pageContext={{ data: node }} />;
    default:
      return null;
  }
};

const UnpublishedPreview = () => {
  const data = usePreviewData();
  if (data) return getPageTemplate(Object.values(data)[0]);
  return null;
};

export default UnpublishedPreview;
